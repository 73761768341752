import Marquee from "@/components/magicui/marquee";
import Image from "next/image";
import placeholderLogo from "@/assets/fugro-logo.svg";
import typsaLogo from "../../../../../src/assets/expresscompanies/Typsa.png";
import jacobsLogo from "../../../../../src/assets/expresscompanies/Jacobs.svg";
import aecomLogo from "../../../../../src/assets/expresscompanies/AECOM.svg";
import adecLogo from "../../../../../src/assets/expresscompanies/ADEC.png"
import atkinsrealisLogo from "../../../../../src/assets/expresscompanies/AtkinsRealis.svg";
import larsentoubroLogo from "../../../../../src/assets/expresscompanies/LarsenToubro.svg";
import wspLogo from "../../../../../src/assets/expresscompanies/wsp.svg";
import swecoLogo from "../../../../../src/assets/expresscompanies/sweco.png";
import robertBirdGroupLogo from "../../../../../src/assets/expresscompanies/RobertBirdGroup.png";

export default function Logos() {
  return (
    <section id="logos">
  <div className="container mx-auto px-4 md:px-8 py-12">
    <h3 className="text-center text-sm font-semibold text-gray-500">
      DEVELOPED WITH INPUT FROM ENGINEERING EXPERTS
    </h3>
    <div className="relative mt-6">
      <Marquee className="max-w-full [--duration:40s] flex items-center gap-8">
        <span className="flex items-center">
          <Image
            width={300}
            height={10}
            src={robertBirdGroupLogo}
            alt={"RobertBirdGroup"}
          />
        </span>
        <span className="flex items-center">
          <Image
            width={300}
            height={10}
            src={adecLogo}
            alt={"ADEC"}
          />
        </span>
        <span className="flex items-center">
          <Image
            width={70}
            height={20}
            src={typsaLogo}
            alt={"Typsa"}
          />
        </span>
        <span className="flex items-center">
          <Image
            width={150}
            height={10}
            src={swecoLogo}
            alt={"Sweco"}
          />
        </span>
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 h-full w-1/3 bg-gradient-to-r from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 h-full w-1/3 bg-gradient-to-l from-background"></div>
    </div>
  </div>
</section>
  );
}
