import CtaSection from '@/app/components/landing-page-components/sections/cta';
import FAQ from '@/app/components/landing-page-components/sections/faq';
import Footer from '@/app/components/landing-page-components/sections/footer';
import Header from '@/app/components/landing-page-components/sections/header';
import Hero2 from '@/app/components/landing-page-components/sections/hero';
import Logos from '@/app/components/landing-page-components/sections/logos';
import Testimonials from '@/app/components/landing-page-components/sections/testimonials';
import Benefits from '@/app/components/landing-page-components/sections/benefits';
import Problem from '@/app/components/landing-page-components/sections/problem';
import Solution from '@/app/components/landing-page-components/sections/solution';
import OnePlace from '@/app/components/landing-page-components/sections/oneplace';
import HowItWorks from '@/app/components/landing-page-components/sections/how-it-works';
import Security from '@/app/components/landing-page-components/sections/security';
import TestimonialsCarousel from '@/app/components/landing-page-components/sections/testimonials-carousel';
import RootLayout from '@/app/layouts/landing-page';
import FeaturesComponent from '@/app/components/landing-page-components/sections/features';
import { PricingSection } from '@/app/components/pricing/pricing';

export default function Home() {
  return (
    <>
      <Header />
      <Hero2 />
      <Logos />
      <Benefits />
      <Solution />
      <OnePlace />
      <TestimonialsCarousel />
      <Security />
      <PricingSection />
      <FAQ />
      <CtaSection />
      <Footer />
    </>
  );
}
