"use client";

import FlickeringGrid from "@/components/magicui/flickering-grid";
import Ripple from "@/components/magicui/ripple";
import Safari from "@/components/safari";
import Section from "@/components/section";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import dashboardImg from '@/assets/dashboard.png';
import placeholderLogo from "@/assets/fugro-logo.svg";
import { useState } from "react";
import Image from 'next/image';
import dashboardImage from '@/assets/landingpage/features/Dashboard.png';
import dataDigitizationImage from '@/assets/landingpage/features/DataDigitization.png';
import dataManagementImage from '@/assets/landingpage/features/DataManagement.png';
import groupingImage from '@/assets/landingpage/features/Grouping.png';
import statisticalAnalysisImage from '@/assets/landingpage/features/StatisticalAnalysis.png';
import creatingGroundModelsImage from '@/assets/landingpage/features/CreateGroundModels.png';
import calculationsImage from '@/assets/landingpage/features/Calculations.png';
import gisMapImage from '@/assets/landingpage/features/GisMap.png';
import crossSectionImage from '@/assets/landingpage/features/CrossSection.png';
import reportingSharingImage from '@/assets/landingpage/features/ReportingSharing.png';

const sectionsHub = [
  {
    id: 'interactive-dashboard',
    title: 'Interactive Dashboard',
    image: dashboardImage,
  },
  {
    id: 'data-digitization',
    title: 'Data Digitization',
    image: dataDigitizationImage,
  },
  {
    id: 'data-management',
    title: 'Data Management',
    image: dataManagementImage,
  },
];

const sectionsAnalytics = [
  {
    id: 'grouping',
    title: 'Grouping',
    image: groupingImage,
  },
  {
    id: 'statistical-analysis',
    title: 'Statistical Analysis',
    image: statisticalAnalysisImage,
  },
  {
    id: 'creating-ground-models',
    title: 'Creating Ground Models',
    image: creatingGroundModelsImage,
  },
  {
    id: 'calculation-tools',
    title: 'Calculation Tools',
    image: calculationsImage,
  },
];

const sectionsVisualisation = [
  {
    id: 'gis-maps',
    title: 'GIS Maps',
    image: gisMapImage,
  },
  {
    id: '2d-cross-sections',
    title: '2D Cross Sections',
    image: crossSectionImage,
  },
];

const sectionsReporting = [
  {
    id: 'reporting-sharing',
    title: 'Reporting & Sharing',
    image: reportingSharingImage,
  },
];

export default function Component() {
  const [selectedSection, setSelectedSection] = useState(sectionsHub[0]);

  return (
    <Section
      subtitle="All Your Geotechnical Needs, Simplified"
      description="Empowering Ground Engineers with Tools for Seamless Productivity, Beyond Just Data Management."
    >
      <div className="flex h-full border border-gray-200">
        {/* Sidebar */}
        <div className="w-1/6 bg-white border-r border-gray-200 p-4 flex flex-col justify-between">
          <div>
            <h2 className="text-xs font-semibold mb-4 whitespace-nowrap">Centralized Geo-data Hub</h2>
            <ul className="space-y-4">
              {sectionsHub.map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => setSelectedSection(section)}
                    className={`flex items-center w-full text-left p-2 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis ${
                      selectedSection.id === section.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <span className="text-xs ml-2">{section.title}</span>
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-xs font-semibold mt-6 mb-4 whitespace-nowrap">Built-In Analytics</h2>
            <ul className="space-y-4">
              {sectionsAnalytics.map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => setSelectedSection(section)}
                    className={`flex items-center w-full text-left p-2 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis ${
                      selectedSection.id === section.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <span className="text-xs ml-2">{section.title}</span>
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-xs font-semibold mt-6 mb-4 whitespace-nowrap">Data Visualization Tools</h2>
            <ul className="space-y-4">
              {sectionsVisualisation.map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => setSelectedSection(section)}
                    className={`flex items-center w-full text-left p-2 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis ${
                      selectedSection.id === section.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <span className="text-xs ml-2">{section.title}</span>
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-xs font-semibold mt-6 mb-4 whitespace-nowrap">Seamless Integration</h2>
            <ul className="space-y-4">
              {sectionsReporting.map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => setSelectedSection(section)}
                    className={`flex items-center w-full text-left p-2 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis ${
                      selectedSection.id === section.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <span className="text-xs ml-2">{section.title}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Main Content with Image */}
        <div className="flex-1 relative bg-blue-50 flex items-center justify-center">
          <div className="relative w-full h-[70vh] max-h-screen overflow-hidden">
            <Image
              src={selectedSection.image}
              alt={selectedSection.id}
              layout="fill"
              objectFit="contain" // Preserves aspect ratio and avoids overflow
              className="rounded-lg"
            />
          </div>
        </div>
      </div>
    </Section>
  );
}


