import axiosHelper from '@/app/utils/axiosHelper';
import { AxiosResponse } from 'axios';

class ProductService {
  private readonly baseUrl: string;
  constructor() {
    this.baseUrl = `${process.env.BACKEND_URL}/backend/product`;
  }
  async getProducts(): Promise<AxiosResponse<any[]>> {
    return await axiosHelper.get(this.baseUrl, {
      headers: { Authorization: `Bearer ${process.env.BACKEND_API_KEY}` },
    });
  }
}

export const productService = new ProductService();
