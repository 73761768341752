import React from 'react';
import video from '@/assets/video1.gif';
import Image from 'next/image';

const VideoComponent = () => {
  return (
    <Image src={video} alt="GDI" unoptimized />
  );
};

export default VideoComponent;