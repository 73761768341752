import React from 'react';
import image from '@/assets/video-oneplace.gif';
import Image from 'next/image';

const VideoComponent = () => {
  return (
    <Image src={image} alt="oneplace"  />
  );
};

export default VideoComponent;
