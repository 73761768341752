import Section from "@/components/section";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { siteConfig } from "@/lib/config";
import { ChevronRight } from "lucide-react"; // Using an arrow icon like in the example image

export default function FAQ() {
  return (
    <Section title="Questions you might have">
      <div className="mx-auto my-12 md:max-w-[800px]">
        <Accordion
          type="single"
          collapsible
          className="flex w-full flex-col items-center justify-center space-y-4"
        >
          {siteConfig.faqsLanding.map((faq, idx) => (
            <AccordionItem
              key={idx}
              value={faq.question}
              className="w-full border-none"
            >
              <AccordionTrigger className="flex items-center justify-between w-full py-3 text-lg font-medium text-left">
                <span className="flex items-center">
                  <ChevronRight className="h-4 w-4 mr-2 text-black" />
                  {faq.question}
                </span>
              </AccordionTrigger>
              <AccordionContent className="pl-6 pr-4 py-2 border-t">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <h4 className="mt-6 text-center text-sm font-medium tracking-tight text-foreground/80">
        Still have questions? Email us at{" "}
        <a href={`mailto:${siteConfig.links.email}`} className="underline">
          {siteConfig.links.email}
        </a>
      </h4>
    </Section>
  );
}
